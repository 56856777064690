import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Gallery } from "gatsby-theme-gallery"
import Container from "../components/utilities/wrapper"
import { MainBlueTitle } from "../components/elements/titles"
import styled from "styled-components"

const Title = styled.div`
  text-align: center;
  margin: 2rem auto;
`

const portfolio = () => {
  return (
    <Layout>
      <SEO title="Portfolio" />
      <Container>
        <Title>
          <MainBlueTitle>Some of our work</MainBlueTitle>
        </Title>
        <Gallery />
      </Container>
    </Layout>
  )
}

export default portfolio
